import React from "react";

import styled from 'styled-components';

function F(K) {
  return ((K-273.15)*1.8)+32;
}

const W = styled.div`
  #temp {
    font-size: 200px;
    font-wieght: bold;
  }
  #icon {
    float: right;
  }
`


export default function ({weather}) {
   const c = weather.current;
  return <W>
  <div>
  <h1>{weather.current.weather[0].main}</h1>
  <div id="icon"><img src={`http://openweathermap.org/img/wn/${weather.current.weather[0].icon}@4x.png`} /></div>
  <div id="temp">{F(c.temp).toFixed(1)}&deg;</div>
  </div>
  <pre>{JSON.stringify(weather, null, 2)}</pre>
  </W>;
}
