import React from "react";
import {useEffect, useState} from "react";

import { createGlobalStyle } from 'styled-components'
import { Colors } from "@blueprintjs/core";

import Weather from './Weather.js';

const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  #root {
    background: ${Colors.DARK_GRAY5};
    width: 824px;
    height: 1200px;
  }
`
//https://openweathermap.org/weather-conditions#How-to-get-icon-URL

export default () => {
   const [weather, setWeather] = useState(null);
   useEffect(() => {
      async function getWeather() {
         const result = await fetch("/weather.php");
         const json = await result.json();
         setWeather(json);
      }
//      setInterval(async () => {
//         getWeather();
//      }, 60000);
setTimeout(() => location.reload(), 10000);
      getWeather();
   }, []);

return (
  <div id="root">
    <GlobalStyle />
    {weather != null && <Weather weather={weather} />}
  </div>
);
}
